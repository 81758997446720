import { IconifyIcon } from "@iconify/react";
import countries from "../data/countries";
import dayjs from "dayjs";
import {
  getCurrencySymbolByCode,
  platformEntity,
} from "../entities/platform.entity";
import { IAPIObjectFormat, LanguageObject } from "../types/global.types";

export const isValidIconifyName = (icon: any): icon is string | IconifyIcon => {
  return (
    typeof icon === "string" ||
    (typeof icon === "object" && icon.body !== undefined)
  );
};

export const generateId = () => Math.random().toString(36).substring(2);

export const getLocationSearchParams = (
  searchString: string
): Record<string, string> => {
  const searchParams = new URLSearchParams(searchString);

  const params: Record<string, string> = {};
  searchParams.forEach((value, key) => {
    params[key] = value;
  });

  return params;
};

export const getTabFromSearchParams = (): [string, number] | null => {
  return null;
};

export function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const countryCodeToEmoji = (alpha2Code: string): string | null => {
  if (!/^[a-zA-Z]{2}$/.test(alpha2Code)) {
    return null;
  }

  const codePoints = alpha2Code
    .toUpperCase()
    .split("")
    .map((char) => 127397 + char.charCodeAt(0))
    .join("-");

  return String.fromCodePoint(
    ...codePoints.split("-").map((cp) => parseInt(cp))
  );
};

export const callingCodes = countries
  .map((country) => {
    return country.callingCodes.map((code) => ({
      name: country.name,
      alpha3code: country.alpha3Code,
      flag: countryCodeToEmoji(country.alpha2Code),
      callingCode: code,
      alpha2Code: country.alpha2Code,
    }));
  })
  .flat()
  .filter((country) => country.callingCode);

export const getCallingCodes = (c: string) =>
  countries.find((country) => country.name.toLowerCase() == c.toLowerCase())
    ?.callingCodes[0];

export const capitalize = (str: string | undefined) =>
  str ? str.charAt(0).toUpperCase() + str.slice(1) : "";

export const toKebabCase = (str: string) =>
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    ?.map((x) => x.toLowerCase())
    .join("-");

export const countryList = countries.map((country) => country.name);

export const getCountryAlpha2code = (country: string) => {
  const count = countries.find((c) => c.name === country);

  return count ? count.alpha2Code?.toLowerCase() : country;
};

export function kebabToSentenceCase(kebabCase: string): string {
  const words = kebabCase.split("-");
  const sentenceCase = words
    .map((word, index) => {
      if (index == 0) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }
      return word.toLowerCase();
    })
    .join(" ");

  return sentenceCase;
}

export function convertSentenceToCamelCase(sentence: string): string {
  const words = sentence.toLowerCase().split(" ");

  const camelCaseWords = words.map((word, index) =>
    index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
  );

  return camelCaseWords.join("");
}

export const isValidIconifyIconName = (iconName: string) => {
  // Regular expression pattern to match Iconify icon name
  const iconifyIconPattern = /^[a-z0-9_-]+:[a-z0-9_-]+$/;

  return iconifyIconPattern.test(iconName);
};

function isEmptyValue(value: unknown): boolean {
  if (Array.isArray(value) && value.length < 1) return true;
  if (typeof value === "object" && value !== null) {
    return Object.keys(value).length === 0;
  } else if (value === "" || value === null) {
    return true;
  }
  return false;
}

export function removeEmptyKeys(obj: { [key: string]: unknown }): {
  [key: string]: unknown;
} {
  const result: { [key: string]: unknown } = {};

  for (const key in obj) {
    if (
      Object.prototype.hasOwnProperty.call(obj, key) &&
      !isEmptyValue(obj[key])
    ) {
      result[key] = obj[key];
    }
  }

  return result;
}

export function truncateString(str: string, maxLength = 90) {
  if (str?.length <= maxLength) {
    return str;
  } else {
    return str?.slice(0, maxLength) + "...";
  }
}

export function convertCamelToRegularString(camelCase: string): string {
  const spacedString = camelCase
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2")
    .toLowerCase();

  return spacedString.charAt(0).toUpperCase() + spacedString.slice(1);
}

export const formatDate = (date: any) =>
  date ? dayjs(date).locale("en").format("MMM D, YYYY") : null;

export const formatAmount = (
  amount: number | string | undefined,
  currency: string | undefined
) =>
  amount == undefined || currency == undefined
    ? ""
    : `${getCurrencySymbolByCode(currency)}${amount.toLocaleString()}`;

// export const resolveLangText = (text: any, lang?: string) => {
//   const { language } = languageEntity.use();
//   if (!text) return "-----------";
//   if (typeof text === "string") return text;

//   return (
//     text[language] ||
//     text.en ||
//     (Object.keys(text).length && text[Object.keys(text)[0]]) ||
//     ""
//   );
// };
export const resolveLangText = (text: any, lang: string) => {
  if (!text) return "";
  if (typeof text === "string") return text;

  return (
    text[lang] ||
    text.en ||
    (Object.keys(text).length && text[Object.keys(text)[0]]) ||
    ""
  );
};

export function isValidEmail(email: string) {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
}

export const isObjectEmpty = (obj: Record<string, any>) => {
  return JSON.stringify(obj) === "{}";
};

export const checkCanWithdrawEarning = (userMarketplace: string) => {
  const marketplaces = platformEntity.get();
  return marketplaces.find((el) => el.code == userMarketplace)
    ?.allowPartnersToWithdrawEarning;
};

export function formatApiObject(data: any): string {
  let queryParams: string[] | string = [];
  const searchQuery = data?.searchParams?.get;
  const path = data?.path?.replace("/", "");

  const obj = data?.queryParams?.get;
  for (const key in obj) {
    if (obj[key] !== "" && obj[key] !== undefined) {
      queryParams.push(`${key}=${obj[key]}`);
    }
  }
  queryParams = queryParams.join("&");

  return `${path}${
    searchQuery?.length > 0 ? `?search_query=${searchQuery}` : ""
  }${
    searchQuery?.length > 0 && queryParams ? "&" : queryParams.length ? "?" : ""
  }${queryParams}`;
}

export function dateTimeToTimestamp(dateTimeString: string): number {
  const date = new Date(dateTimeString);

  const timestamp = date.getTime();

  return timestamp;
}

// export function dateTimeToTimestamp(
//   dateTimeString: string
// ): number | undefined {
//   const date = new Date(dateTimeString);

//   if (isNaN(date.getTime())) {
//     console.error(`Invalid date in dateTimeToTimestamp: ${dateTimeString}`);
//     return undefined;
//   }

//   const timestamp = date.getTime();
//   console.log(
//     `dateTimeToTimestamp: Converted ${dateTimeString} -> ${timestamp}`
//   );

//   return timestamp;
// }

export function yearToTimestamp(year: number) {
  const date = new Date(year, 0, 1);
  return date.getTime();
}

export const getPhoneCode = (c: string | any) => {
  const countryPhoneCodes: { [key: string]: string } = {
    dk: "45",
    se: "46",
    es: "34",
    ng: "234",
    gb: "44",
    ca: "1",
    us: "1",
    it: "39",
  };

  const code = c?.toLowerCase();
  return countryPhoneCodes[code] || "45";
};
