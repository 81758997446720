import { entity } from "simpler-state";
import { IBrandInfo, ISignupProductForm } from "../types/global.types";
import { IProduct } from "../types/products.types";

export const supplierRegEntity = entity<{
  supplierDetails: any;
  refId: string;
  brandInfo: IBrandInfo | null;
  marketplace: string;
  selectedBrandProducts: Array<IProduct>;
  createdProduct: Array<ISignupProductForm>;
}>({
  supplierDetails: {
    phone: "",
    phonePrefix: "45",
    email: "",
    name: "",
    logo: "",
    headquarterAddress: "",
    headquarterCountry: "",
    isAuthorizedManager: true,
    authorizedManagerEmail: "",
    authorizedManagerPhonePrefix: "45",
    authorizedManagerPhone: "",
    authorizedManagerName: "",
    headquarterCity: "",
    headquarterZipcode: null,
    state: "",
    periodType: "monthly",
    isPointOFSale: false,
    isCharity: false,
    long: "",
    lat: "",
    industry: "",
    brandCategoryIds: [],
    sameAsContact: true,
  },
  refId: "",
  marketplace: "",
  brandInfo: null,
  selectedBrandProducts: [],
  createdProduct: [],
});

export const regSupplier = (data: any) => {
  supplierRegEntity.set((prevState) => ({
    ...prevState,
    supplierDetails: { ...prevState.supplierDetails, ...data },
  }));
};
export const saveRef = (ref: string, marketplace: string) => {
  supplierRegEntity.set((prevState) => ({
    ...prevState,
    refId: ref,
    marketplace,
  }));
};
export const saveBrandInfo = (brandInfo: IBrandInfo) => {
  supplierRegEntity.set((prevState) => ({
    ...prevState,
    brandInfo: brandInfo,
  }));
};
export const saveSelectedBrandProduct = (products: Array<IProduct>) => {
  supplierRegEntity.set((prevState) => ({
    ...prevState,
    selectedBrandProducts: products,
  }));
};
export const saveCreatedProduct = (products: Array<ISignupProductForm>) => {
  supplierRegEntity.set((prevState) => ({
    ...prevState,
    createdProduct: products,
  }));
};
